import React, { useEffect, useState } from "react";
import Select from "react-select";
import "./Filter.css";
import { fetchShows } from "../utils";
import {useURLParams} from "../SearchBox/SearchBox"


const Filter = ({setFilter}) => {
    const [options, setOptions] = useState([]);
    const [urlParams, setUrlParams] = useURLParams("shows");
    const urlShows = urlParams.split(",");
    const urlOptions = options.filter(o => urlShows.includes(o.value));

    const onFilterChange = (options) => {
        const optionsValue = options.map(o => o.value);
        setUrlParams(optionsValue);
        setFilter(optionsValue);
    }

    useEffect(() => setFilter(urlOptions.map(o => o.value)), [urlParams, options]);

    useEffect(() => {
        async function replace() {
            const data = await fetchShows();
            const shows = data.shows.map(show => {return { label: show, value: show }});
            setOptions(shows);
        }
        replace().catch(console.log);
    }, []);

    return (
        <Select 
        closeMenuOnSelect={false}
        defaultValue={[]}
        isMulti
        placeholder={"Select shows"}
        isLoading={!options.length}
        options={options}
        value={urlOptions}
        classNamePrefix={"react-select"}
        className={"filter"}
        isSearchable
        onChange={onFilterChange} />
    );
}

export default Filter;