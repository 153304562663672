import React, { useEffect, useState } from 'react';
import "./SearchBox.css"
import { useSearchParams } from 'react-router';

export const useURLParams = (key) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchedValue = searchParams.get(key) || "";

  const update = (value) => {
    if(value.length === 0) {
      searchParams.delete(key);    
    }else {
      searchParams.set(key, value);
    }
    setSearchParams(searchParams);
  }
  
  return [searchedValue, update];
}

const SearchBox = ({ onInputChange }) => {
  const [inputValue, setInputValue] = useState("");
  const [searched, setUrlParams] = useURLParams("search", setInputValue);
  useEffect(() => setInputValue(searched), [searched]);
  useEffect(() => onInputChange(inputValue), [inputValue]);

  const handleChange = (event) => {
    const newValue = event.target.value;
    setUrlParams(newValue);
    setInputValue(newValue);
  };

  return (
    <input
      className="search-box"
      type="text"
      value={inputValue}
      onChange={handleChange}
      placeholder="Quote a TV show scene"
    />
  );
};

export default SearchBox;